import { defineStore } from 'pinia';
import type { LinkedGenArt } from './interfaces/graphicStoreInterfaces';
import type { VehicleGraphicHtml } from '~/types/interfaces';
import type { Step } from '~/types/types';
export const useGraphicSearchStore = defineStore({
  id: 'useGraphicSearchStore',
  state: () => ({
    carGenArts: <LinkedGenArt[]>[],
    linkedGenArts: <LinkedGenArt[]>[],
    graphicsHtml: <VehicleGraphicHtml>{},
    graphicStep: <Step>"vehicle"
  }),
  actions: {
    previousStep() {
      switch (this.graphicStep) {
          case "subGroup":
            this.graphicStep = 'mainGroup';
              break;
          case 'mainGroup':
            this.graphicStep = 'vehicle';
              break;
      }
    }
  }
});