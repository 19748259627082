import { useAppStore } from "~/store/useAppStore";
import type { VehicleGenericInformations, VehicleInformation, VehiclePlateData } from "~/types/interfaces";
import { useGraphicSearchStore } from "~/store/useGraphicSearchStore";
import type { LinkedGenArt } from "~/store/interfaces/graphicStoreInterfaces";
import type { VehicleType } from "~/types/vehicle";

const _useVehicle = () => {
    const appStore = useAppStore()
    const graphicSearchStore = useGraphicSearchStore();
    const { resetQuote } = useQuote()
    const runtimeConfig = useRuntimeConfig()

    const vehicleInformationsCookie = useCookie<VehicleInformation | null>(runtimeConfig.public.cookies.vehicleInformation)
    const vehiclePlateData = useCookie<VehiclePlateData | null>(runtimeConfig.public.cookies.vehiclePlateData);
    const vehicleGenericData = useCookie<VehicleGenericInformations | null>(runtimeConfig.public.cookies.vehicleGenericInformations);
    const linkedGenArtsCookies = useCookie<LinkedGenArt[] | null>(runtimeConfig.public.localStorage.linkedGenArts);

    const vehicleInformations = computed(() => { return {
        info: vehicleInformationsCookie.value,
        plateInfo: vehiclePlateData.value,
        genericInfo: vehicleGenericData.value,
        linkedGenArts: linkedGenArtsCookies.value
    }})
    
    const hasPlateInformations = computed(() => vehicleInformations.value.plateInfo && vehicleInformations.value.plateInfo !== null)
    const hasGenericInformations = computed(() => vehicleInformations.value.genericInfo && vehicleInformations.value.genericInfo !== null)
    const haslinkedGenArts = computed(() => vehicleInformations.value.linkedGenArts && vehicleInformations.value.linkedGenArts !== null)
    const hasVehicleInformations = computed(() => hasPlateInformations.value && hasGenericInformations.value)
    const brandName = computed(() => vehiclePlateData?.value?.mark ?? vehicleGenericData?.value?.mfrName)

    const vehicleTypeList = ref<VehicleType[]>([])
    const vehiclesPlateDatas = ref<VehiclePlateData[]>([])

    /**
     * Resets the vehicle-related information stored in various state variables and objects.
     * Optionally, it can also reset the session quote.
     * 
     * This function clears data related to the vehicle's plate, generic details, and cookies,
     * as well as resets the `appStore` and `graphicSearchStore` properties related to vehicle information.
     * 
     * @function resetVehicleInformations()
     * @param {boolean} [resetCurrentQuote=false] - If true, the session quote will also be reset to `null`. Defaults to `false`.
     * @param {'plate' | 'type' | 'all' | 'none'} [resetType='none'] - Specifies which type of vehicle information to reset:
     *    - 'plate' - Resets only the vehicle plate data.
     *    - 'type' - Resets only the vehicle type data.
     *    - 'all' - Resets both plate and type data.
     *    - 'none' - Does not reset any specific data (default type).
     * @returns {void} This function does not return a value.
     */
    function resetVehicleInformations(resetCurrentQuote: boolean = false, resetType: 'plate' | 'type' | 'all' | 'none' = 'none'): void {
        vehiclePlateData.value = null
        vehicleGenericData.value = null
        vehicleInformationsCookie.value = null
        linkedGenArtsCookies.value = null

        if(resetType === 'plate' || resetType === 'all') vehiclesPlateDatas.value = []
        if(resetType === 'type' || resetType === 'all') vehicleTypeList.value = []

        appStore.infoCar.actualKType = ''
        appStore.infoCar.actualImmat = ''
        appStore.infoCar.actualVin = ''

        if(resetCurrentQuote) resetQuote()

        graphicSearchStore.$reset()
    }

    /**
     * Sets the vehicle information in the relevant state and cookies.
     * This function updates the vehicle's KType, VIN, and optional plate number.
     * Additionally, it can store Plate and Generic information if provided.
     * 
     * @function setVehicleInformations()
     * @param {number} kType - The KType of the vehicle, used for identification.
     * @param {string} vin - The Vehicle Identification Number (VIN) of the vehicle.
     * @param {string} [plateNumber] - An optional vehicle plate number. Defaults to an empty string if not provided.
     * @param {VehiclePlateData} [plateInformations] - Optional Plate information to be stored.
     * @param {VehicleGenericInformations} [genericInformations] - Optional Generic information to be stored.
     * 
     * @returns {void} This function does not return a value.
     */
    function setVehicleInformations(kType: number, vin: string, plateNumber?: string, plateInformations?: VehiclePlateData, genericInformations?: VehicleGenericInformations): void {
        vehicleInformationsCookie.value = {
            actualKType: kType.toString(),
            actualImmat: plateNumber ?? '',
            actualVin: vin,
            lastKType: "",
            lastImmat: ""
        }

        if(plateInformations) vehiclePlateData.value = plateInformations
        if(genericInformations) vehicleGenericData.value = genericInformations
    }

    return {
        vehicleInformations,
        hasPlateInformations,
        hasGenericInformations,
        haslinkedGenArts,
        hasVehicleInformations,
        brandName,
        vehicleTypeList,
        vehiclesPlateDatas,
        resetVehicleInformations,
        setVehicleInformations
    }
};

/**
 * A composable that provides reactive vehicle-related information and state management.
 * It allows access to the vehicle's plate data, generic information, linked generative art data, 
 * and offers computed properties to check the availability of these data. It also includes 
 * a function to reset the vehicle information.
 * 
 * @function useVehicle()
 * 
 * @returns {{
 *   vehicleInformations: Ref<{
 *     info: VehicleInformation | null,
 *     tecdoc: VehiclePlateData | null,
 *     tecrmi: VehicleGenericInformations | null,
 *     linkedGenArts: LinkedGenArt[] | null
 *   }>,
 *   hasPlateInformations: Ref<boolean>,
 *   hasGenericInformations: Ref<boolean>,
 *   haslinkedGenArts: Ref<boolean>,
 *   hasVehicleInformations: Ref<boolean>,
 *   brandName: Ref<string | undefined>,
 *   vehicleTypeList: Ref<VehicleType[]>,
 *   vehiclesPlateDatas: Ref<VehiclePlateData[]>,
 *   resetVehicleInformations: (resetCurrentQuote?: boolean) => void,
 *   setVehicleInformations: (kType: number, vin: string, plateNumber?: string, plateInformations?: VehiclePlateData, genericInformations?: VehicleGenericInformations) => void
 * }}  
 * 
 * - `vehicleInformations`: A ref containing an object with vehicle-related information such as `info`, `genericInfo`, `plateInfo`, and `linkedGenArts`.
 * - `hasPlateInformations`: A ref indicating whether Plate information is available.
 * - `hasGenericInformations`: A ref indicating whether generic information is available.
 * - `haslinkedGenArts`: A ref indicating whether linked generative art information is available.
 * - `hasVehicleInformations`: A ref indicating whether both Plate and generic information are available.
 * - `brandName`: A ref that returns the vehicle's brand name, or `undefined` if not available.
 * - `vehicleTypeList`: A ref that contains a list of available vehicle types.
 * - `vehiclesPlateDatas`: A ref that contains a list of available vehicle plate data.
 * - `resetVehicleInformations`: A function to reset all vehicle-related information, with an optional `resetCurrentQuote` parameter to reset the session quote.
 * - `setVehicleInformations`: A function to set the vehicle's KType, VIN, optional plate number, and associated Plate and Generic information.
 */
export const useVehicle = createSharedComposable(_useVehicle)